<template>
    <div class="main">
        Processing redirection...
    </div>
</template>

<script>
import store from './../../store'

export default {
    name: 'LoginSpeaker',
    mounted() {
        store.commit('setHistory', this.$route.params.id)
        if(store.state.AUTH.is_loged && store.state.AUTH.type == 2) {
            this.$router.push('/speaker/webinar-answers/' + this.$route.params.id)
        }else {
            this.$router.push('/login')
        }
    }
}
</script>